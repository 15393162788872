<template>
  <div class="section">
    <div>
      <UserFeedback :target="t('componentNames.examPreview')" class="mr-4 mt-1"></UserFeedback>
      <b-field>
        <p class="subtitle">{{localExam.title}}</p>
      </b-field>
      <b-field>
          <p>{{ localExam.description }}</p>
      </b-field>
      <hr />
    </div>
    <div v-for="(question, index) in localExam.questions" :key="question">
      <ExamPreviewQuestion :indexQ="index" :question="question"></ExamPreviewQuestion>
    </div>
    <b-loading v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../../store/config_actions";
import ExamPreviewQuestion from "./ExamPreviewQuestion";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "ExamPreview",
  mixins: [CommonHelpers],
  components: {
    ExamPreviewQuestion,
    UserFeedback
  },

  props: {
    idExam: { type: String, required: true },
  },

  data: function () {
    return {
      localExam: {},
      isLoading: false,
    };
  },

  mounted() {
    //this.getExam();
  },

  created(){
    this.getExam();
  },

  watch: {},

  computed: {},

  methods: {

    getExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_GET_EXAM_BY_ID, this.idExam)
        .then((payload) => {
          this.localExam = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>