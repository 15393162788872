<template>
  <div class="section p-4">
    <div v-if="localQuestion">
      <b-field>
        <p class="subtitle">{{indexQ + 1}}. {{ localQuestion.title }}</p>
      </b-field>
      <div v-if="currentImage" class="columns is-centered">
        <div class="column is-6">
          <b-field>
            <b-image :src="currentImage" ratio="16by9"> </b-image>
          </b-field>
        </div>
      </div>
      <b-field>
        <p>{{ localQuestion.text }}</p>
      </b-field>
      <b-field v-if="localQuestion.answers.length > 0">
        <ul class="p-3">
          <li
            v-for="option in localQuestion.answers"
            :key="option.id"
            :class="{
              'text-sucess': option.isCorrect,
            }"
          >
            {{ option.text }}
          </li>
        </ul>
      </b-field>
      <b-field v-else>
        <b-input
          type="textarea"
          :placeholder="t('question.types.open')"
          disabled
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import { SERVER } from '@/store/config_apis'
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../../store/config_actions";

export default {
  name: "ExamsPreviewQuestion",
  mixins: [CommonHelpers],
  components: {},

  props: {
    indexQ: {type: Number, required: true},
    question: { type: String, required: true },
  },

  data: function () {
    return {
      localQuestion: null,
      editMode: false,
      tmpValue: null,
      isLoading: false,
    };
  },

  mounted() {
    //this.getQuestion(this.question);
  },

  computed:{
     currentImage() {
      if (this.localQuestion.image) {
        return `${SERVER}/api/MediaFile/File/${this.localQuestion.image}`;
      }

      return null;
    },
  },

  created(){
    this.getQuestion(this.question);
  },

  methods: {

    getQuestion(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_GET_ONE_BY_ID, id)
        .then((payload) => {
          this.localQuestion = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status == 400){
            this.mDangerSnack(this.t('validation.examWithRemovedQuestions'));
          }
          this.apiProblem(error)
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: #006600;
}
</style>