<template>
  <div class="home">
    <ExamPreview :idExam="$route.params.idExam"  :isCombo ="$route.params.isCombo"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ExamPreview from '@/components/exam/examMentor/ExamImagePreview'

export default {
  name: 'ExamImagePreviewView',
  components: {
    ExamPreview
  }
}
</script>